/// <reference lib="DOM" />
/// <reference lib="ESNext" />

import '@popperjs/core';
import 'bootstrap';

import GLightbox from 'glightbox';

window.addEventListener('load', () => {
    /**
     * Установка галереи для всплывающих окон
     */
    const lightbox = GLightbox({
        autoplayVideos: true,
        loop: true,
        touchNavigation: true,
    });

    /**
     * Обработка для модального окна
     * описания кухни страны
     */
    const countryKitchenDescription = document.getElementById('countryKitchenDescription');
    const countryKitchenDescriptionContent = document.getElementById('countryKitchenDescriptionContent');

    if (countryKitchenDescription && countryKitchenDescriptionContent) {
        countryKitchenDescription.addEventListener('show.bs.modal', (event: any) => {
            const button = event.relatedTarget
            countryKitchenDescriptionContent.textContent = button.getAttribute('data-bs-whatever');
        });
    }

    const moreInfoModal = document.getElementById('moreInfoModal');
    const moreInfoModalContent = document.getElementById('moreInfoModalDescriptionContent');

    if (moreInfoModal && moreInfoModalContent) {
        moreInfoModal.addEventListener('show.bs.modal', (event: any) => {
            const button = event.relatedTarget
            moreInfoModalContent.textContent = button.getAttribute('data-bs-whatever');
        });
    }
});